import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import figureFormatter from '@/services/utils/figureFormatter'
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const ngoPdfPrinter = () => {
    const {currencyToWord} = handlePurchase();
    const {commaFormat} = figureFormatter();
    const store = useStore();
    const userName = store.state.user.user.name;
    const exportToPDF = (company, data, barcode, qrcode, btnFunc) => {
        var tableItems = getTableItems(data, (btnFunc === 'receipt' || btnFunc === 'contra'), btnFunc);
        if(btnFunc === 'contra'){
            var tableItemsDebit = getTableItems(data, false, btnFunc);
        }
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(data, tableItems, barcode, btnFunc),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: data.voucher_no
            }
        }

        pushIntoTable(doc, data, tableItems, btnFunc)
        if(btnFunc === 'contra'){
            pushIntoDebitTable(doc, data, tableItemsDebit, btnFunc)
        }
        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        if(!date) {
            return
        }
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        if(!date) {
            return
        }
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }
    const getContent = (data, tableItems, barcode, btnFunc) => {
        const accountHead = (btnFunc === 'payment') ?
                                        data.ledgers.find(i => i.debit === null || i.debit === 0) :
                                        (btnFunc === 'receipt') ?
                                            data.ledgers.find(i => i.credit === null || i.credit === 0)
                                            : null
        return  [
            {
                text: getTitle(btnFunc),
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '40%',
                        stack: [
                            {
                                text: accountHead ? [{text: (btnFunc === 'payment') ? 'PAID FROM: ' : (btnFunc === 'receipt') ? 'RECEIVED IN: ' : '' , bold: true}, {text: (btnFunc === 'contra') ? '' : accountHead.head.name }] : ''
                            },

                            {
                                text: (data.payment_ref && btnFunc !== 'journal') ? [{text: 'CHEQUE NO: ', bold: true }, { text: data.payment_ref }] : ''
                            },

                            {
                                text: (data.payment_ref_date && btnFunc !== 'journal') ? [{text: 'CHEQUE DATE: ', bold: true }, {text: formatToDdMmYy(data.payment_ref_date) }] : ''
                            },

                            {
                                text: (btnFunc === 'journal' && tableItems.length > 0 && tableItems[0].txn_no) ? [ {text: 'PR / PO REFERENCE: ', bold: true }, { text: tableItems[0].txn_no} ] : ''
                            },

                            {
                                text: (btnFunc === 'journal' && tableItems.length > 0 && tableItems[0].tag_register) ? [ {text: 'TAG REFERENCE: ', bold: true } ] : ''
                            },
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: [ {text: 'VOUCHER DATE: ', bold: true }, {text: formatToDdMmYy(data.date)} ]
                            },

                            {
                                text: [ {text: 'VOUCHER NO: ', bold: true}, {text: data.voucher_no}]
                            },

                            {
                                svg: barcode
                            },
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: (btnFunc === 'journal') ? ['*', 'auto', 'auto', 'auto', 'auto'] : ['*', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
			          fillColor: function (rowIndex) {
                            return (rowIndex === 0 || rowIndex === tableItems.length + 1) ? '#f3f2f7' : null;
                        },
                    hLineWidth: function () {
                        return 0.5;
                    },
                    vLineWidth: function () {
                        return 0.5;
                    }
		        }
		    },

            (btnFunc === 'contra') ? {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto'],
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex) {
                        return (rowIndex === 0 || rowIndex === tableItems.length + 1) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function () {
                        return 0.5;
                    },
                    vLineWidth: function () {
                        return 0.5;
                    }
		        }
		    } : '',

            {
                columns: [
                    {
                        width: '65%',
                        text: [
                            {
                                text: 'Amount in word: ',
                                bold: true
                            },

                            {
                                text: data.total_amount ? currencyToWord(data.total_amount) : ''
                            }
                        ]
                    }
                ],
                margin: [0, 10]
            },

            {
                alignment: 'justify',
                text: [{ text: data.note ? 'Voucher Memo \n' : '', bold: true}, {text: data.note} ]
            },

            formatSignatureData()

        ]
    }

    const getTitle = (btnFunc) => (btnFunc === 'payment') ? 'PAYMENT VOUCHER' : (btnFunc === 'receipt') ? 'RECEIPT VOUCHER' : (btnFunc === 'contra') ? 'CONTRA VOUCHER' : (btnFunc === 'journal') ? 'JOURNAL VOUCHER' : ''

    const formatSignatureData = () => {

        let signatures = [ 'Prepared By', 'Reviewed By', 'Approved By' ];

        return {
            alignment: 'justify',
            absolutePosition: { x: 60, y: 710 },
            columns: signatures.map(getSingleSignature)
        }
    }

    const getSingleSignature = (title) => {
        return {
            width: '43%',
            stack: [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 58,
                            y2: 0,
                            lineWidth: 1
                        }
                    ]
                },
                {
                    margin: [ 0, 5, 0, 0 ],
                    text: `${title} `,
                    color: 'black',
                    bold: true,
                    alignment: 'left',
                }
            ]
        }
    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: '',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]

                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }
    const pushIntoTable = (doc, data, tableItems, btnFunc) => {
        const tableHeader = getTableHeader(btnFunc);
        doc.content[2].table.body.push(tableHeader);
        tableItems.forEach(item => {
            const rowData = getRowData(data, item, btnFunc, (btnFunc === 'receipt' || btnFunc === 'contra'));
            doc.content[2].table.body.push(rowData);
        });
        if(btnFunc === 'contra') return;
        const lastRow = (btnFunc === 'journal') ?
                        [{text: 'Total', alignment: 'right', colSpan: 3}, '', '',commaFormat(data.total_amount), commaFormat(data.total_amount)] :
                        [{text: 'Total', alignment: 'right', colSpan: 3}, '', '', commaFormat(data.total_amount)];
        doc.content[2].table.body.push(lastRow);
    }
    const pushIntoDebitTable = (doc, data, tableItemsDebit, btnFunc) => {
        const tableHeader = getTableHeader(btnFunc, true);
        doc.content[3].table.body.push(tableHeader)
        tableItemsDebit.forEach(item => {
            const rowData = getRowData(data, item, btnFunc, false);
            doc.content[3].table.body.push(rowData);
        });
    }
    const getTableItems = (data, isDebitNull = false, btnFunc) => {
        if(btnFunc === 'journal'){
            return data.ledgers.sort((a, b) => {
                if (a.debit !== 0 && b.debit === 0) {
                  return -1;
                }
                if (a.debit === 0 && b.debit !== 0) {
                  return 1;
                }
                return 0;
            });
        }
        if(typeof data.ledgers === undefined){
            return [];
        }
        if(isDebitNull){
            return data.ledgers.filter(ledger => ledger.debit === null || ledger.debit === 0)
        }
        return data.ledgers.filter(ledger => ledger.credit === null || ledger.credit === 0)
    }
    const getTableHeader = (btnFunc, isDebitNull = false) => {
        if(btnFunc === 'journal') {
            return [
                { text: 'Accounts Description' },
                { text: 'Line Description' },
                { text: 'Party Name' },
                { text: 'Debit', alignment: 'right' },
                { text: 'Credit', alignment: 'right' }
            ]
        }
        return [
            { text: (btnFunc === 'contra' && isDebitNull) ? 'Transfer to/Received in' : (btnFunc === 'contra') ? 'Transfer from/Paid from' : 'Accounts Description' },
            { text: 'Line Description' },
            { text: 'Party Name' },
            { text: 'Amount', alignment: 'right' },
        ]
    }
    const getRowData = (data, item, btnFunc, isDebitNull = false) => {
        if(btnFunc === 'journal'){
            return [{ stack: [
                { text: item.head.name },
                { text: item.business ? item.business : ''},
                { text: item.cost_centre ? item.cost_centre.name : ''},
                { text: item.project ? item.project : ''},
                { text: item.activity ? item.activity.name : ''},
                { text: item.ngoab_head ? item.ngoab_head.name: ''} ]
                },
                { text: item.note },
                { text: item.party ? item.party.name : '' },
                { text: item.debit ? commaFormat(item.debit) : '', alignment: 'right' },
                { text: item.credit ? commaFormat(item.credit) : '', alignment: 'right' }
            ]
        }

        return [{ stack: [
            { text: item.head.name },
            { text: item.business ? item.business : ''},
            { text: item.cost_centre ? item.cost_centre.name : ''},
            { text: item.project ? item.project : ''},
            { text: item.activity ? item.activity.name : ''},
            { text: item.ngoab_head ? item.ngoab_head.name: ''} ]
            },
            { text: item.note },
            { text: item.party ? item.party.name : '' },
            { text: isDebitNull ? commaFormat(item.credit) : commaFormat(item.debit), alignment: 'right' }]
    }

    return {
        exportToPDF,
        formatDate
    }
}

export default ngoPdfPrinter;
